export const appTitle = "BDO IVA | Intelligent Virtual Assistant";
export const companyName = "BDO Digital";
export const logoWidth = 643;
export const logoHeight = 124;

export const helpUrl = "";

export const possibleLocales = ["en-US", "en-IN", "en-GB", "en-AU"];

export const baseSecurableName = "bdo.mygpt.web";

const SECONDS_PER_MINUTE = 60;
export const NUM_IDLE_COUNTDOWN_SECONDS = SECONDS_PER_MINUTE * 2;
export const NUM_IDLE_SECONDS = SECONDS_PER_MINUTE * 15;

export const rowsPerPageOptions = [10, 25, 50];

const fromStorage = localStorage.getItem("defaultRowsPerPage") as
  | string
  | null
  | undefined;
export const defaultRowsPerPage = fromStorage ? Number(fromStorage) : 10;

export const FORMAT_EMPTY = "--";

// https://day.js.org/docs/en/display/format#list-of-localized-formats
export const FORMAT_DAYJS_APPEND_TIMEZONE: boolean = false;
export const FORMAT_DAYJS_API = "YYYY-MM-DDTHH:mm:ssZ";
export const FORMAT_DAYJS_DATE = "MM/DD/YYYY";
export const FORMAT_DAYJS_MONTH = "MMMM YYYY";
export const FORMAT_DAYJS_MONTH_DAY = "MMM. d";
export const FORMAT_DAYJS_YEAR = "YYYY";
export const FORMAT_DAYJS_TIME = "hh:mm A";
export const FORMAT_DAYJS_DATETIME =
  FORMAT_DAYJS_DATE + " " + FORMAT_DAYJS_TIME;

export const MINIMUM_DATE_NUMBER_PARSED = 946684800000;

export const FORMAT_TRUE = "Yes";
export const FORMAT_FALSE = "No";

export const PRIME_CALENDAR_HOUR_FORMAT: "12" | "24" = "12";

export const DURATION_MS_TOAST_SUCCESS = 3_000;
export const DURATION_MS_TOAST_ERROR = 12_000;
export const DURATION_MS_TOAST_BUSY_DELAY = 200;
export const DURATION_MS_TOAST_MIN = 1_000;

export type AuditFields =
  | "createDate"
  | "createUser"
  | "modifyDate"
  | "modifyUser";

// JS differs from C#, so we need to use the same values as the backend
export const MAX_SAFE_INTEGER = 2_147_483_647;

export const virtualDirRoot = window.location.origin + "/";
export const configApi = virtualDirRoot + "api/config";
export const filesApi = virtualDirRoot + "api/fileManagement";
export const serviceName = virtualDirRoot + "breeze";
export const storeExpirationMs = 1000 * 60 * 60 * 24 * 30; // 30 days
export const dayMs = 1000 * 60 * 60 * 24; // 1 day
export const throttleMs = 200;
export const skipFieldsForMessage = [
  "rights",
  "createdDate",
  "id",
  "createdByUserId",
  "modifiedDate",
  "modifiedByUserId",
  "relatedRecordId",
  "sortOrder",
];

export const stateKeys = {
  metadataHash: "-V2-metadataHash",
  annotatedMetadataHash: "-V2-annotatedMetadataHash",
  lookupsHash: "-V2-lookupsHash",
  breezeMetadata: "-V2-breezeMetadata",
  lookups: "-V2-lookups",
  annotatedMetadata: "-V2-annotatedMetadata",
  savedRoute: "-V2-savedRoute",
};

export const hashes = {
  currentMetadataHash: "",
  currentAnnotatedMetadataHash: "",
  currentLookupsHash: "",
};

export const colors = [
  "#54a575",
  "#657c91",
  "#fd7e14",
  "#ad1931",
  "#02a5e2",

  "#df8639",
  "#218f8b",
  "#98002e",
  "#333333",
  "#e7e7e7",
  "#1183B1",
  "#B46A1E",
  "#006663",
  "#4e5f6f",
  "#5d000d",
  "#23211e",
  "#87coe9",
  "#f2c89e",
  "#549c9a",
  "#9da6b6",
  "#828282",
  "#005d81",
  "#794604",
  "#00514e",
  "#364451",
  "#d5e6f6",
  "#f7e1ca",
  "#a6c4c4",
  "#a4a4a4",
  "#dcdcdc",
];

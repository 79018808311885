import { MsalService } from "@azure/msal-angular";
import { Injectable } from "@angular/core";
import invariant from "tiny-invariant";
import { Observable, catchError, map, of } from "rxjs";
import { jwtDecode } from "jwt-decode";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private msalService: MsalService) {}

  private _storageKey: string | undefined;
  private getStorageKey() {
    if (this._storageKey !== undefined) {
      return this._storageKey;
    }

    invariant(window.__settings, "No settings set");
    invariant(window.__settings.adTenantId, "No AdTenantId set");
    invariant(window.__settings.adClientId, "No AdClientId set");

    // loop over all local storage to find the right one
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i) ?? "";
      const storedVal = localStorage.getItem(key) ?? "";
      try {
        const parsed = JSON.parse(storedVal);
        if (
          typeof parsed === "object" &&
          parsed &&
          "secret" in parsed &&
          typeof parsed.secret === "string" &&
          "target" in parsed &&
          typeof parsed.target === "string"
        ) {
          if (parsed.target.includes(`/user_impersonation`)) {
            this._storageKey = key;
            return key;
          }
        }
      } catch (e) {}
    }

    return "";
  }

  public getUserSessionToken() {
    const storedVal = localStorage.getItem(this.getStorageKey());

    if (!storedVal) {
      return undefined;
    }

    const parsed = JSON.parse(storedVal);
    if (
      typeof parsed === "object" &&
      parsed &&
      "secret" in parsed &&
      typeof parsed.secret === "string"
    ) {
      return parsed.secret;
    }

    return undefined;
  }

  public getHasExpiredUserSession() {
    const jwtToken = this.getUserSessionToken();
    if (jwtToken) {
      const token = jwtDecode(jwtToken);
      if (
        token &&
        typeof token === "object" &&
        "exp" in token &&
        typeof token.exp === "number"
      ) {
        const expirationDate = token.exp;
        const currentDateTime = Math.floor(new Date().getTime() / 1_000);
        if (currentDateTime >= expirationDate) {
          return true;
        }

        return false;
      }
    }

    return undefined;
  }

  public refreshToken(): Observable<boolean> {
    const account = this.msalService.instance.getAllAccounts()[0];

    return this.msalService
      .acquireTokenSilent({
        scopes: ["user.read"],
        account: account,
        authority: `https://login.microsoftonline.com/${window.__settings?.adTenantId}/`,
        redirectUri: `https://${document.location.host}/`,
        forceRefresh: true,
      })
      .pipe(
        map((x) => {
          console.log("After aquireTokenSilent, received response", x);
          return true;
        }),
        catchError((error) => {
          console.error("received error from msal service", error);
          return of(false);
        }),
      );
  }
}

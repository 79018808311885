import { HttpClient } from "@angular/common/http";
import { from } from "rxjs";
import { AuthService } from "src/app/services/auth/auth.service";

export class BaseService {
  constructor(
    protected http: HttpClient,
    private routeRoot: string,
    protected authService: AuthService,
  ) {
    this.routeRoot = routeRoot;
    this.http = http;
    this.authService = authService;
  }

  protected route(specific?: string): string {
    return "/api/" + this.routeRoot + specific; // TODO: Update me
  }

  protected ensureValidTokenObs = from(
    (async () => {
      if (this.authService.getHasExpiredUserSession()) {
        console.error(
          "Detected that the token is expired, so attempting to refresh token...",
        );
        let refreshTokenResponse: boolean | undefined;
        try {
          refreshTokenResponse = await this.authService
            .refreshToken()
            .toPromise();
        } catch (error: unknown) {
          console.error("ran into an issue refreshing token", error);
        }

        console.log("got refresh token response", refreshTokenResponse);

        if (!refreshTokenResponse) {
          console.error(
            "failed to refresh token, clearing localStorage and attempting a full page reload",
          );
          localStorage.clear();
          window.location.reload();
        }
      }
    })(),
  );
}
